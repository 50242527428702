import imageInput from '@/mixins/image-input.js';
import {mapState} from 'vuex';
import NewsPostModel from '../news/models/news-post.model';

export default {
    data() {
        return {
            item: {}
        }
    },
    methods: {
        approve() {
            let data = new NewsPostModel(this.item);

            let formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            }

            this.$store.dispatch("news/updateItem", {id: this.id, data: formData});
        }
    },
    created() {
        this.$store.dispatch("news/getItemById", this.id).then((res) => {
            this.item = res;
        });
    },
    computed: {
        ...mapState({
            categories: (state) => state.categories.items,
        }),
        id() {
            return this.$route.params.id;
        },
    },
    mixins: [imageInput]
}
